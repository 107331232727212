.sociosDiv {
  width: 100%;
}

.uploadsTitle {
  font-weight: bold;
  color: #e5007e;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
  .uploadContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #252525;
    max-width: 88vw;
    padding-bottom: 8rem;

    .titleUp {
    }

    .cardsUp {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      .boxSection {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.8rem;
        border-radius: 7px;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
        background-color: #fff;
        padding: 0.5rem 1rem;
        border: solid 1px #fff;

        .css-1oqqzyl-MuiContainer-root {
          max-width: 100%;
          padding: 0;
        }

        .boxContainer {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1rem;
        }

        .cardUpload {
          position: relative;
          display: flex;
          width: 290px;
          min-height: 100px;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: row;
          gap: 0.6rem;
          cursor: pointer;
          border-radius: 7px;
          box-shadow: 0 0 5px 0 rgb(0 0 0 / 18%);
          background-color: #fff;
          padding: 1rem 1rem 1rem 5rem;

          &.uploaded {
            border: dotted 2px green;
            box-shadow: none;
            opacity: 0.7;
          }

          .titleUpCard {
            font-weight: 500;
            font-size: 0.9rem;
          }

          .descriptionUpCard {
            color: #e5007e;
            font-weight: 450;
          }

          .cardIcon {
            position: absolute;
            top: 1rem;
            left: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            box-shadow: 5px 5px 21px 0 rgb(0 0 0 / 14%);
            border: dashed 1px #e5007e;
            border-radius: 100%;
            color: #e5007e;

            svg {
              font-size: 1.3rem;
            }
          }

          .flex{
            display: flex !important
          }

          .uploadInfo {
            align-items: center;
            gap: 3px;
            margin-top: 1rem;
            font-size: 0.8rem;
            display: flex !important;


            svg {
              font-size: 1.2rem;
              margin:0
            }
          }

          h3 {
            font-size: 1rem;
            color: #252525;
          }

          p {
            font-weight: 400;
            margin: 0.3rem;
            color: #252525;
            font-size: 0.8rem;
          }

          button {
            color: #25252599;
            background-color: transparent;
            border: none;
          }

          &:hover {
            border: dashed 1px #e5007e;
            opacity: 1;
            button {
              color: #e5007e;
            }
          }

          &.blocked {
            pointer-events: none;
            filter: grayscale(100%);
            opacity: 0.8;
          }
        }
      }
    }

    .uploadFooter {
      position: fixed;
      left: 0px;
      bottom: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: #2f2f2f;
      border-top: 0.1px solid rgba(55, 55, 55, 0.2);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;

      footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        p{
          color: #FFF;
          font-size: 0.9rem;
          margin: 0;
          padding: 0;
        }

        button {
          /* margin-inline: 0.75rem !important; */
          border-radius: 7px;
          padding: 0.1rem 0.8rem;
          font-size: 0.9rem;
          margin: 0;
        }
      }
    
  }
  }
}
