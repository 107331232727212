.breadcrumbs {
  a {
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
  a:hover {
    text-decoration: underline;
  }
  .selected {
    color: #e5007e;
  }
}
