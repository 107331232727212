.table-contacts-container {
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  .table-group {
    margin-right: 0;
    width: 100%;
    background-color: #fafafa;

    h4 {
      background-color: #e5007e;
      color: #fff;
      padding: 10px;
      text-align: center;
      margin: 0;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      /* margin-left: 5%; */
    }

     table td {
      padding-right: 20px;
    }

    table td:last-child {
      padding-right: 0;
    }
  }
}

.table-contacts-container.desktop {
  flex-direction: row;

  .table-group.desktop {
    /* min-width: 95%; */
  }
}
