body{
  background-color: #eaeaea91;
  color: #252525;
  
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #e5007e;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #252525;
  padding: 0.7rem 2rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  width: 100%;

  .logo-and-drawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    .logo{
      width: 6.5rem;
      height: auto;
      padding-top: 0.2rem;
      margin-left: 1rem;
    }
  }

  button{
    text-transform: capitalize;
    background-color: transparent;
    box-shadow: none;
    border-radius: 7px;
    margin: 0;
  }

  .headerButtonMenu{
    color: #FFF;
  }
}




.menuIcon {
  color: #e5007e;
}

.headerButtonMenu {
  cursor: pointer;
  font-size: 18px;
}

.headerMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 4rem;
}

.center {
  display: flex;
  justify-content: center;
  /* text-align: center; */
  align-items: center;
}

.link {
  color: #e5007e;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}

.css-acxifg {
  padding: 20px !important;
}
/* 
.css-1wnsr1i{
  text-align: center;
} */
.title {
  text-align: center;
}

.Modal {
  display: flex;
  justify-content: center;
}

.css-1o64x6j-MuiPaper-root-MuiCard-root,
.css-1mlfq7d-MuiPaper-root-MuiCard-root {
  color: #e5007e !important;
}
.cardForecastButton {
  color: #e5007e !important;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
}

a {
  text-decoration: none;
}

.css-i4bv87-MuiSvgIcon-root {
  margin-inline: 10px;
}
.css-x3ygu5-MuiButtonBase-root-MuiButton-root {
  font-size: 0.75rem !important;
}

/* Uploads */
.css-5zulsx-MuiTypography-root {
  max-width: 70% !important;
  text-align: center;
}

/* Menu Home */
.linkMenu {
  color: black;
  /* text-decoration:overline; */
}

/* Form */
.modal-content {
  display: flex;
  justify-content: space-evenly;
}


footer{
  p{
    &.entreRights{
      color: #FFF!important;
      font-size: 0.7rem!important;
      margin-left: 1rem!important;
    }
  }

  .footerButtons{
    display: flex;
    gap: 7px;
  }
}
.footer-link {
  align-items: center;
  text-align: center;
  display: flex;
  color: #252525 !important;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.footer-link :visited {
  color: #FFF !important;
}

.footer-link a {
  color: #FFF !important;
  text-decoration: underline #FFF;
}

.css-144matw {
  position: fixed !important;
}

/* .css-1oqqzyl-MuiContainer-root{
  margin-bottom: 200px !important;
} */

#dependentQuestion {
  margin-left: 20px !important;
}

.Questions {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 3rem 8rem 1rem;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-top: 0 !important;
}

.status-container {
  display: flex;
  align-items: center;
}

@media screen and (width <= 1200px) {
  .menuHome {
    flex-direction: column !important;
    text-align: center !important;
    margin: 0 !important;
  }
  .cardHome {
    max-width: 100% !important;
    justify-content: center !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    width: 80%;
  }
  .statusHome {
    padding: 0 !important;
    max-width: 80% !important;
  }
  .css-ogf2z5-MuiPaper-root-MuiCard-root {
    padding: 0 !important;
    margin: 0 !important;
  }
  .css-110t4wx-MuiGrid-root {
    width: 100% !important;
  }
}

.css-1cn3yto, .css-1lym95h-MuiGrid-root {
  flex-direction: row !important;
  flex-flow: inherit !important;
}

.css-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 16.5px 14px !important
}