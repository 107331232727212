.pageForecast {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 85vh;
  padding-top: 3rem;

  .forecastContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    color: #252525;
    width: 90%;
    height: auto;
    padding-bottom: 10rem;
    gap: 1rem;
    .title {
      text-align: left;
      max-width: 70%;
    }

    .infos {
      display: flex;
      gap: 10px;
      flex-direction: row;

      .cardUpload {
        width: 50vw;

        &.uploaded {
          border: dotted 2px green;
          box-shadow: none;
          opacity: 0.7;
        }

        .titleUpCard {
          font-weight: 500;
          font-size: 0.9rem;
        }

        .descriptionUpCard {
          color: #e5007e;
          font-weight: 450;
        }

        .cardIcon {
          position: absolute;
          top: 1rem;
          left: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          box-shadow: 5px 5px 21px 0 rgb(0 0 0 / 14%);
          border: dashed 1px #e5007e;
          border-radius: 100%;
          color: #e5007e;

          svg {
            font-size: 1.3rem;
          }
        }

        .uploadInfo {
          align-items: center;
          gap: 3px;
          margin-top: 1rem;
          font-size: 0.8rem;
          display: flex !important;

          svg {
            font-size: 1.2rem;
            margin: 0;
          }
        }

        .fileInfo {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        .uploader {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .forecastFooter {
      position: fixed;
      left: 0px;
      bottom: 2.3rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #2f2f2f;
      border-top: 0.1px solid rgba(55, 55, 55, 0.2);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
      padding: 0.5rem 0;

      button {
        border-radius: 7px;
        padding: 0.1rem 0.8rem;
        font-size: 0.9rem;
        margin-right: 1rem;
      }
    }
    .uploadedMessage {
      font-size: 0.9em;
    }
  }

  footer {
    position: fixed !important;
    padding: 0 !important;
  }
}
