.CardCnpjContainer {
  position: relative;
  display: flex;
  min-width: 220px;
  max-width: 220px;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.6rem;
  cursor: pointer;
  border-radius: 7px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  padding: 0.8rem 1rem 1rem;
  border: solid 1px #fff;
  width: calc(25% - 14px - 3rem);

  .flex {
    display: flex !important;
  }

  .cardTitleAndIcon {
    width: 100%;
    display: flex;
    gap: 15px;
    align-items: center;

    h3 {
      max-width: 100%;
    }
  }

  .aprovado {
    &::before {
      content: "• ";
      color: rgb(6 241 6);
      font-size: 1.6em;
    }
  }

  .emanalise,
  .emanálise {
    &::before {
      content: "• ";
      color: yellow;
      font-size: 1.6em;
    }
  }

  .cardIcon {
    /* position: absolute; */
    top: 1rem;
    left: 1rem;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    display: flex !important;
    box-shadow: 5px 5px 21px 0 rgb(0 0 0 / 14%);
    border-radius: 100%;
    color: #e5007e;

    svg {
      font-size: 1.3rem;
    }
  }

  h3 {
    font-size: 1rem;
    color: #252525;
  }

  p {
    font-weight: 400;
    color: #252525;
    margin-top: 0;
  }

  button {
    color: #25252599;
    background-color: transparent;
    border: none;
  }

  &:hover {
    border: solid 1px #e5007e;

    button {
      color: #e5007e;
    }
  }

  &.blocked {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.8;
  }
}
