.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 14px !important;
  height: 1.4375em !important;
}

.formsContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    max-width: 88vw;
    color: #252525;
    padding-top: 2.5rem;

    p, h1, h2, h4{
      margin: 0.8rem 0;
    }

    .alert{
      padding: 1rem;
    }

    .boxSection{
      position: relative;
      display: flex;
      width: 100%;
      height: auto;
      justify-content: space-between;
      align-items: normal;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 0.8rem;
      border-radius: 7px;
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
      background-color: #fff;
      padding: 1rem 2rem;
      border: solid 1px #fff;

      .css-1oqqzyl-MuiContainer-root {
        max-width: 100%;
        padding: 0;
      }

      .css-1b3l6lk-MuiGrid-root{
        max-width: 100% !important;
      }

      .firstBox{
        max-width: 100% !important;
      }
    }
  }

  .formFooter{
      position: fixed;
      left: 0px;
      bottom: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: #2f2f2f;
      border-top: 0.1px solid rgba(55, 55, 55, 0.2);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;

      footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        p{
          color: #FFF;
          font-size: 0.9rem;
          margin: 0;
          padding: 0;
        }

        button {
          /* margin-inline: 0.75rem !important; */
          border-radius: 7px;
          padding: 0.1rem 0.8rem;
          font-size: 0.9rem;
          margin: 0;
        }
      }
    
  }

