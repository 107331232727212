.homeContainer {
  padding: 0rem 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  width: 100%;
  min-height: 85vh;
  padding-top: 3rem;

  .cardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;

    .cards {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100% !important;
      height: auto !important;
      gap: 1rem !important;
    }
  }

  .statusBox {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center !important;
    gap: 7px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 0.1rem 1rem;
    text-align: left;
    font-weight: 500;
    color: #252525;

    svg {
      color: #252525 !important;
    }

    &.statusAprovado {
      background: linear-gradient(
        180deg,
        rgba(135, 253, 135, 0.54) 0,
        #fbfbfb 100%
      );
    }

    &.status {
      background: linear-gradient(
        180deg,
        rgb(247, 247, 126),
        rgb(255, 255, 255) 100%
      );
    }
  }

  footer {
    position: fixed !important;
    padding: 0 !important;
  }
}

.select-proposal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  min-height: calc(100vh - 120px);

  h2 {
    margin: 0;
  }

  .cards {
    /* width: 70vw; */
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 36px;
  }

  footer {
    position: fixed !important;
    padding: 0 !important;
  }
}
